/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as globals from '../../globals';
import AnyObject = globals.AnyObject;
import AnyFunction = globals.AnyFunction;

export type EncodeDecodeKeys = {
    encode: AnyObject;
    decode: AnyObject;
    recordMappings?: AnyObject<AnyFunction>;
};

export interface IBaseKeyConfig {
    main: EncodeDecodeKeys;

    [key: string]: EncodeDecodeKeys;
}

export abstract class Translator<Config extends IBaseKeyConfig = IBaseKeyConfig> {
    // eslint-disable-next-line no-useless-constructor, no-empty-function
    constructor(public keys: Config) {}

    abstract encode(record: AnyObject): AnyObject;

    abstract decode(record: AnyObject): AnyObject;

    protected translate<Result = AnyObject>(
        keys: AnyObject,
        record: AnyObject,
        item: Record<string, any>
    ): Result {
        if (!item) {
            item = {};
        }
        for (const k in keys) {
            const value = keys[k];
            item[value] = record[k];
        }
        return item as Result;
    }
}
