/* © 2017-2025 Booz Allen Hamilton Inc. All Rights Reserved. */

import { jwtDecode } from 'jwt-decode';
import { ExternalUserAccount } from 'sarsaparilla/login';
import { RidbHeaders } from '../constants/headers';
import * as Globals from '../globals';
import { AuthLocalStorageKeys } from '../constants/auth';
import iterateEnum = Globals.iterateEnum;
import Headers = Globals.Headers;
import { LocalStorage } from './localStorage';

export enum AuthTargetedTypes {
    USER = 'user',
    USER_ACCOUNT = 'userAccount',
    TOKEN = 'token',
    API_KEY = 'apiKey',
    API_KEYS = 'apiKeys',
}

class Auth {
    // eslint-disable-next-line no-useless-constructor, no-empty-function
    constructor(
        private readonly storage = LocalStorage,
        private readonly key = 'developer-session'
        // eslint-disable-next-line no-empty-function
    ) {}

    /**
     *
     * @param type, type of data retrieved
     * @param data
     */
    public target<T = any>(type: AuthTargetedTypes, data?: any): T | object {
        const key = `${this.key}-${type}`;
        if (data) {
            this.setData(key, data);
        }
        const info = this.getData<T>(key);
        switch (type) {
            case 'user':
                return info || {};
            default:
                return info;
        }
    }

    public clearData = () => {
        iterateEnum(AuthLocalStorageKeys, (key, value) => {
            this.storage.removeItem(value);
        });
    };

    public isLoggedIn() {
        const token = this.target(AuthTargetedTypes.TOKEN);
        if (token) {
            const jwt = jwtDecode(token);
            // Not expired
            if (jwt.exp > Date.now() / 1000) {
                return true;
            }
        }

        const refreshData = this.getRefreshData();
        if (refreshData) {
            const refreshed = this.syncRefreshToken(refreshData);
            if (refreshed) {
                return true;
            }
        }

        // Expired
        this.clearData();
        return false;
    }

    public getRefreshData(): Partial<ExternalUserAccount> {
        const acct = this.target(AuthTargetedTypes.USER_ACCOUNT);

        if (acct == null) return null;

        const account = acct?.account;
        const refreshID = acct.refresh_id;
        const access_token = acct.access_token;

        if (account && refreshID && access_token) {
            return {
                account,
                refresh_id: refreshID,
                access_token,
            };
        }

        return null;
    }

    public syncRefreshToken(refreshData: Partial<ExternalUserAccount>) {
        const url = `${process.env.API}/accounts/login/v2/refresh`;

        const xhr = new XMLHttpRequest();

        xhr.open('POST', url, false);
        xhr.setRequestHeader('Authorization', `Bearer ${refreshData.access_token}`);

        xhr.send(
            JSON.stringify({
                account_id: refreshData.account.account_id,
                refresh_id: refreshData.refresh_id,
            })
        );

        if (xhr.status === 200) {
            const resp = JSON.parse(xhr.responseText);
            this.target(AuthTargetedTypes.TOKEN, resp.access_token);
            this.target(AuthTargetedTypes.USER_ACCOUNT, resp);

            return true;
        }

        return false;
    }

    private setData(key: string, data: any) {
        if (this.storage.getItem(key)) {
            this.storage.removeItem(key);
        }
        //we should only stringify here if data is an object. right now is parsing even string hence causing a lot of errors in the console
        this.storage.setItem(key, JSON.stringify(data));
    }

    private getData<T = any>(key: any): T {
        const data = this.storage.getItem(key);
        if (data) {
            if (data?.length && data.length < 1) {
                return null;
            }
            let final = data;
            try {
                //we should only parse here if data is an object. right now is parsing even string hence causing a lot of errors in the console
                final = JSON.parse(data);
            } catch (ignored) {
                console.error(ignored);
            }
            return final;
        }
        return null;
    }

    private getAuthHeader = () => `Bearer ${this.target(AuthTargetedTypes.TOKEN)}`;

    public getHeaders = (headers: Headers): Headers => {
        const authToken = this.target(AuthTargetedTypes.TOKEN);
        const apiKey = this.target(AuthTargetedTypes.API_KEY);
        const finalHeaders = { ...headers };
        if (authToken && authToken !== 'undefined') {
            finalHeaders[RidbHeaders.AUTH] = this.getAuthHeader();
        }
        if (apiKey && apiKey !== 'undefined') {
            finalHeaders[RidbHeaders.API_KEY] = apiKey;
        }
        return finalHeaders;
    };
}

export default new Auth();
